import React, { useState } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, Button, UncontrolledTooltip } from 'reactstrap';
import classes from './IconBar.module.css';
import '../../index.css';
import firebase from '../../Config/firebase'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart as heartOutline, faComment } from '@fortawesome/free-regular-svg-icons'
import { faLocationArrow, faHeart as heartSolid, faCode } from '@fortawesome/free-solid-svg-icons'

const EMAIL_CONTENT = "mailto: andrew.w.fiel@gmail.com?subject = Interview Invitation"

const COMMENT_OPTIONS = [
    "Great work!",
    "I'd hire you",
    "Generic compliment!"
    ];

const db = firebase.firestore();

export default function IconBar(props) {

    const [heartLiked, tapHeart] = useState(false);
    const [likes, setLikes] = useState(props.likes);
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const like = () => {
        incrementLikes(heartLiked? -1 : 1)
        tapHeart(!heartLiked);
    }

    const incrementLikes = (incVal) => {
        db.collection('Posts')
          .doc(props.id)
          .get()
          .then((post) => {
            if (!post.empty) {
              let likes = post.data().likes;
              modifyLikes(likes + incVal);
            }
          })
      }

    const modifyLikes = (newVal) => {
        setLikes(newVal);
        db.collection('Posts')
          .doc(props.id)
          .set({ likes: newVal }, { merge: true })
    }

    const getPost = () => {
        return db.collection('Posts')
                 .doc(props.id)
                 .get();
    }

    const postComment = (newCom) => {
        getPost().then((post) => {
            let coms = post.data().comments;
            coms.push(newCom)
            db.collection('Posts')
              .doc(props.id)
              .set({ comments: coms }, { merge: true })
            props.setComments(coms);
        })
    }


    const getCommentButtons = () => {
        return COMMENT_OPTIONS.map((cmt, i) => {
            return (
            <Row key={i}>
                <Col>
                    <Button className="w-100 mb-1" onClick={() => {postComment(cmt); toggleExpanded()}}>{cmt}</Button>
                </Col>
            </Row>
            );
        })
    };

    return (
        <Row name="Post Buttons">
            <Col xs="1" sm="2" md="1">
                <FontAwesomeIcon icon={heartLiked ? heartSolid : heartOutline}
                                 onClick={like}
                                 className={ (heartLiked ? 'heart-like' : 'heart-of-darkness')}
                                 id="date-sort-icon"/>
            </Col>

            <Col xs="1" sm="2" md="1">
                <FontAwesomeIcon onClick={toggleExpanded} icon={faComment} flip="horizontal" className="white-icon" id="comment-sort-icon"/>
                <Modal isOpen={expanded} toggle={toggleExpanded}>
                    <ModalHeader toggle={toggleExpanded} className="pb-0">Add a Comment</ModalHeader>
                    <ModalBody className={"pt-0"}>
                        {getCommentButtons()}
                    </ModalBody>
                </Modal>
            </Col>
            <Col xs="1" sm="2" md="1">
                <a href={EMAIL_CONTENT} className="hidden-link-black" target="_blank" rel="noopener noreferrer">
                    {/* The DM icon needs to be two layered since the outline-only version is only in fa pro */}
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon icon={faLocationArrow} className="white-icon"/>
                        <FontAwesomeIcon icon={faLocationArrow} className="black-icon" transform="shrink-6 right-1 up-1" id="like-sort-icon"/>
                    </span>
                </a>
            </Col>
            <Col>
                {(heartLiked ? ('Liked by you and ' + (likes - 1) + ' others') : 'Liked by ' + likes)}
            </Col>
            <Col xs="1" sm="2" md="1" className="text-right mr-1">
                <a href={props.gitHubLink ? props.gitHubLink : ''} target="_blank" rel="noopener noreferrer" className={props.gitHubLink ? 'white-icon' : 'icon-disabled'}>
                    <FontAwesomeIcon icon={faCode} id=""/>
                </a>
            </Col>
        </Row>
    )
}