import React, {useState, useEffect} from 'react'
import {Container, Row, Col, ButtonGroup, Button, Progress, UncontrolledTooltip, Badge} from 'reactstrap';
import classes from './ViewPost.module.css'
import '../../index.css'
import {Link, useHistory} from 'react-router-dom'
import firebase from '../../Config/firebase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faChevronLeft, faStopwatch, faCalendar } from '@fortawesome/free-solid-svg-icons'

const db = firebase.firestore();

export default function ViewPost(props) {

    const [data, setData] = useState(props.location?.state?.data);
    const history = useHistory();

    const resourceList = () => {
        return data.toolList?.map((item, i) => {
        return <li key={i}>{item}</li>
        })
      }

    const getDisplayDate = (seconds) => {
        let d = new Date(1970, 0, 1);
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
        ];
        d.setSeconds(seconds)
        return months[d.getMonth()] + ', ' + d.getFullYear();
    }

    const getTitle = () => {
        if (data?.isWip) {
            return (
                <h3>{data.title} <Badge color="primary">WIP</Badge></h3>
            )
        } else {
            return (
                <h3>{data.title}</h3>
            )
        }
    }
  
    const projectDetails = () => {
        return (
            <Container className="mt-3 pt-2">
                <Row>
                    <Col className="pr-0">
                        {getTitle()}
                    </Col>
                </Row>
            
                <hr/>
                <p>{data.description}</p>
                <hr/>
                <p>Resources/Tools:</p>
                <ul>
                    {resourceList()}
                </ul>
                <hr/>
                <Row>
                    <Col className="col-2 text-center">
                        <FontAwesomeIcon icon={faCalendar} color="var(--dark-white)" id={"calendar-icon-" + data.id}/>
                        <UncontrolledTooltip placement="top" target={"calendar-icon-" + data.id}>Created On</UncontrolledTooltip>
                    </Col>
                    <Col className="text-center">
                        {getDisplayDate(data.createdOn.seconds)}
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col className="col-2 text-center">
                        <FontAwesomeIcon icon={faStopwatch} color="var(--dark-white)" id={"stopwatch-icon-" + data.id}/>
                        <UncontrolledTooltip placement="top" target={"stopwatch-icon-" + data.id}>Relative Effort</UncontrolledTooltip>
                    </Col>
                    <Col className="text-center">
                        <Progress value={ data.effort } max={ 7 }/>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col>
                        <ButtonGroup className="w-100">
                            <Button onClick={() => {history.push('/')}}>
                                    <FontAwesomeIcon icon={faChevronLeft} color="var(--dark-white)"/>
                            </Button>
                            <Button disabled={!data.gitHubLink} onClick={() => {window.open(data.gitHubLink)}} >
                                    <FontAwesomeIcon icon={faGithub} color="var(--dark-white)"/>
                            </Button>
                            
                        </ButtonGroup>
                    </Col>
                </Row>
            </Container>
        );
    };

    const getProjectById = (id) => {
        db.collection('Posts')
            .doc(id)  
            .get()
            .then(doc => {
                if(doc.exists) {
                    setData(doc.data())
                } else {
                    props.history.push({pathname: '/'});
                }
            })
    }

    useEffect(() => {
        if (typeof props?.location?.state?.data == 'undefined') {
            getProjectById(props.match.params.id)
        }
    }, [])

    return (
        <div className="main-content">
            { data? projectDetails() : ''}
        </div>
    )
}