import React from 'react';
import '../../../index.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faComment, faHeart, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'

import { Row, Col, UncontrolledTooltip } from 'reactstrap';

export default function Footer(props) {

    const filterOptions = ["date", "comments", "likes"];

    const filterIcons = {
        date: faCalendar,
        comments: faComment,
        likes: faHeart
    }

    const filterTooltips = {
        date: 'Sort by Date',
        comments: 'Sort by Comments',
        likes: 'Sort by Likes'
    }

    const clickIcon = (fltr) => {
        if (fltr === props.filter) {
            props.setUpDown(!props.downward);
        } else {
            props.setFilter(fltr);
        }
    }

    const getUpDown = (selected) => {
        if (selected) {
            return <FontAwesomeIcon icon={(props.downward) ? faSortDown : faSortUp} className="ml-1"/>
        } else {
            return <div/>
        }
    }

    const options = filterOptions.map((fltr) => {
        return (
            <Col className="align-self-center" key={fltr}>
                <div onClick={() => clickIcon(fltr)} className={(props.filter === fltr) ? 'white-icon' : 'black-icon'}>
                    <FontAwesomeIcon icon={filterIcons[fltr]} size="lg" id={fltr + "icon"}/>
                    {getUpDown(props.filter === fltr)}
        <UncontrolledTooltip placement="top" target={fltr + "icon"}>{filterTooltips[fltr]}</UncontrolledTooltip>
                </div>
            </Col>
        );
    })

    return (
        <div className="fixed-footer footer-icons">
            <Row className="w-100 text-center">
                {options}
            </Row>
        </div>
    );
}