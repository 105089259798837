import * as firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyBvhXk0bUs4lp4TBzccBnX7RnnsXmPcvk0",
    authDomain: "portfolio-site-117fd.firebaseapp.com",
    databaseURL: "https://portfolio-site-117fd.firebaseio.com",
    projectId: "portfolio-site-117fd",
    storageBucket: "portfolio-site-117fd.appspot.com",
    messagingSenderId: "836237283993",
    appId: "1:836237283993:web:d6582c8518e2370ee9de08",
    measurementId: "G-NM8C3DK248"
  };

  firebase.initializeApp(firebaseConfig);
  
  firebase.firestore();

  export default firebase