import React, {useState, useEffect} from 'react';
import { Row, Col } from 'reactstrap';

import PostList from '../../../Components/PostList/PostList'
import Footer from '../Footer/Footer'

import firebase from "../../../Config/firebase"
import '../../../index.css'

const db = firebase.firestore();

export default function Main() {

    const [posts, setPosts] = useState([]);
    const [filter, setFilter] = useState("date");
    const [down, setUpDown] = useState(true);
  
    const refreshPosts = () => {
      db.collection('Posts')
        .limit(20)
        .get()
        .then((data) => {
          if (!data.empty) {
            let newPosts = [];
            data.forEach((item) => {
              newPosts.push(
                {
                  id: item.id,
                  ...item.data()
                }
              )
            })
            setPosts(newPosts)
          }
        })
    }
  
    useEffect(() => {
      refreshPosts();
      window.scrollTo(0, 0);
    }, [filter, down])

    const sortFunctions = {
      date: (a, b) => {
          let val = new Date(a.createdOn?.toDate()) - new Date(b.createdOn?.toDate());
          return (down ? val * -1 : val)
      },
      effort: (a, b) => {
          let val =  a.effort - b.effort;
          return (down ? val * -1 : val)
      },
      likes: (a, b) => {
          let val =  a.likes - b.likes;
          return (down ? val * -1 : val)
      },
      comments: (a, b) => {
          let val =  a.comments?.length - b.comments?.length;
          return (down ? val * -1 : val)
      }
    }
  
    return (
      <div className="main-content">
        <PostList posts={posts.sort(sortFunctions[filter])}/>
        <Footer setUpDown={setUpDown} setFilter={setFilter} filter={filter} downward={down}/>
      </div>
    );
  }