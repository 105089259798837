import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PostDescription from '../PostDescription/PostDescription';
import PostSubComment from '../PostSubComment/PostSubComment';

export default function PostComments(props) {
    const MAX_VISIBLE_COMMENTS = 3;

    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const comments = props.comments.map((comment, i) => {
        return <PostDescription poster={'Anonymous #' + i} description={comment} key={i}/>
    })

    if (props.comments && props.comments.length > 0 && props.comments.length <= MAX_VISIBLE_COMMENTS) {
        return (comments);
    } else if(props.comments && props.comments.length > MAX_VISIBLE_COMMENTS) {
        return (
            <div>
                <div onClick={toggleExpanded} >
                    <PostSubComment text={'View ' + props.comments.length + ' Comments'} />
                </div>
                <Modal isOpen={expanded} toggle={toggleExpanded}>
                    <ModalHeader toggle={toggleExpanded}>Comments</ModalHeader>
                    <ModalBody>
                        {comments}
                    </ModalBody>
                </Modal>
            </div>
        );
    } else {
        return <div></div>
    }

    
}