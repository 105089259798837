import React, {useState} from 'react';
import {Row, Col, Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import '../../../index.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faDownload, faSquare} from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faGithubSquare } from '@fortawesome/free-brands-svg-icons'

import resume from '../../../media/resume.pdf'

export default function Header() {

    const [display, toggleDisplay] = useState(false);

    const toggle = () => {
        toggleDisplay(!display);
    }

    return (
        <Navbar className="main-nav nav-fill w-100" fixed="top" expand="md">
            <NavbarBrand href="/" className="mr-auto hidden-link-white my-auto">Andrew Fiel</NavbarBrand>
            <NavbarToggler className="mr-2" onClick={toggle}>
                <FontAwesomeIcon icon={faBars} color="white"/>
            </NavbarToggler>
            <Collapse isOpen={display} navbar>
                <Nav className="ml-auto text-right" navbar>
                    <NavItem>
                        <NavLink href={resume} className={"combined-nav-icon"} download="AndrewFielResume.pdf">
                            <Row>
                                <Col className="pr-0 text-right my-auto">
                                    <span className="fa-layers fa-fw">
                                        <FontAwesomeIcon className={"inner-nav-icon"} icon={ faSquare } size="lg"/>
                                        <FontAwesomeIcon icon={ faDownload } color="var(--dark-gray)" transform="shrink-3" />
                                    </span>
                                </Col>
                                <Col className="pl-1 text-left my-auto">
                                    <p className="inner-nav-text my-auto">Resume</p>
                                </Col>
                            </Row>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={"nav-icon"} href="https://www.linkedin.com/in/andrew-fiel/" target="_blank">
                            <Row>
                                <Col className="pr-0 text-right my-auto">
                                    <FontAwesomeIcon  icon={ faLinkedin } size="lg"/>
                                </Col>
                                <Col className={"pl-1 text-left my-auto"}>
                                    <p className="inner-nav-text my-auto">LinkedIn</p>
                                </Col>
                            </Row>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={"nav-icon"} href="https://github.com/andrew-fiel" target="_blank">
                            <Row>
                                <Col className="pr-0 text-right my-auto">
                                    <FontAwesomeIcon icon={ faGithubSquare } size="lg"/>
                                </Col>
                                <Col className="pl-1 text-left">
                                    <p className="inner-nav-text my-auto">GitHub</p>
                                </Col>
                            </Row>
                        </NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
    );
}