import React from 'react';
import {Container } from 'reactstrap';

export default function PostSubComment(props) {

    if (props.text) {
        return (
            <Container className="justify-content-start pl-0">
                <div className={"post-sub-comment"}>{props.text}</div>
            </Container>
        );
    } else {
        return <div></div>
    }
}