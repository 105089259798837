import React from 'react';

import Header from './Layout/Home/Header/Header'
import Main from './Layout/Home/Main/Main'

import ViewPost from './Layout/ViewPost/ViewPost'

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'

import './App.css';


function App() {

  return (
    <div className="App">
      <Router>
        <Header />
        <Switch>
          <Route path="/" exact>
            <Main />
          </Route>
          <Route path="/post/:id" render={routeProps => (<ViewPost {...routeProps}/>)}>
          </Route>
        </Switch>
      </Router>
      
    </div>
  );
}

export default App;
