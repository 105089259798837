import React, { useState, useEffect } from 'react';
import { Row, CardFooter } from 'reactstrap';
import { Link } from 'react-router-dom';

import IconBar from '../IconBar/IconBar';
import PostDescription from '../PostDescription/PostDescription';
import PostComments from '../PostComments/PostComments';

import profileImage from "../../media/profile_photo.png";
import classes from './PostCard.module.css';
import '../../index.css';

import firebase from '../../Config/firebase';


import {Col, Card, CardHeader} from 'reactstrap';

const storage = firebase.storage();
const storageRef = storage.ref();

export default function PostCard(props) {

    const [comments, setComments] = useState(props.data.comments);
    const [pictureLink, setPictureLink] = useState();

    useEffect(() => {
        getImage(props.data.imageRef)
    }, [])

    const getImage = (name) => {
        let img = storageRef.child(name);
        img.getDownloadURL().then((link) => {
            setPictureLink(link)
        })
    }

    return (
          <Card className="m-1" width="100%">
            <CardHeader className="align-items-left">
              <Row>
                <Col className="my-auto">
                  <div className="post-header">
                    <a href="https://www.linkedin.com/in/andrew-fiel/" target="_blank" rel="noopener noreferrer">
                        <img src={profileImage} alt="Profile Photo" className="profile-photo"></img>
                    </a>
                    <div className="post-identifiers">
                      <a href="https://www.linkedin.com/in/andrew-fiel/" target="_blank" rel="noopener noreferrer">
                        <strong>{props.data.author}</strong>
                      </a>
                      <a className="hidden-link-white" href="https://en.wikipedia.org/wiki/COVID-19_pandemic" target="_blank" rel="noopener noreferrer">Quarantine</a>
                    </div>
                   </div>
                  
                </Col>
              </Row>
            </CardHeader>
            <Link to={{
              pathname: 'post/' + props.data.id,
              state: { data: props.data }
            }}>
              <div className="square-card-image">
                <img width="100%" src={pictureLink} alt={props.data.title + " Image"} />
              </div>
            </Link>
            <CardFooter>
              <IconBar id={props.data.id} likes={props.data.likes} gitHubLink={props.data.gitHubLink} setComments={setComments}/>
              <PostDescription poster={props.data.author} description={props.data.title} linkedIn/>
              <PostComments comments={comments}/>
            </CardFooter>
          </Card>
    );
}
