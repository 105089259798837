import React from 'react';
import {Container } from 'reactstrap';

export default function PostDescription(props) {

    const author = () => {
        if (props.linkedIn) {
            return (
                <a href="https://www.linkedin.com/in/andrew-fiel/" target="_blank" rel="noopener noreferrer">
                    <strong>{props.poster}</strong>
                </a>
            );
        } else {
            return <strong>{props.poster}</strong>
        }
    }

    if (props.poster && props.description) {
        return (
            <Container className="justify-content-start pl-0">
                {author()} {props.description}
            </Container>
        );
    } else {
        return <div></div>
    }
}