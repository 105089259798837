import React from 'react';
import { Row, Col } from 'reactstrap';
import '../../index.css'
import PostCard from '../PostCard/PostCard';



export default function PostList(props) {

    return (
         <Row className="justify-content-center w-100 mx-0">
            <Col sm="12" md="6" lg="5" xl="4">
                {props.posts.map((p) => 
                    <PostCard data={p} key={p.id}/>
                )}
            </Col>
         </Row> 
    );
}
